<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-hourglass icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Demande
                <div class="page-title-subheading">Détail de la demande.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div id="commande" #commande>
                            <div class="row">
                                <div class="col-md-7">
                                    <div class="text space">
                                        <h4><strong>Demande</strong></h4>
                                    </div>
                                </div>
                                <!-- <div class="col-md-5 text-md-right">
                                    <h4><strong>#{{cmd.ref_facture_cmd}}</strong></h4>
                                </div> -->
                            </div>

                            <div class="row">
                                <div class="col-md-7">
                                    <img width="170"
                                        src="{{env.transportorUsersAssetsBaseURL + demande?.user_transportor?.profile_user}}"
                                        alt="">
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <strong>Client:</strong><br />
                                    {{demande.user_transportor?.nom_user + " " +
                                    demande.user_transportor?.prenom_user}}<br />
                                    {{demande.user_transportor?.email_user}}<br />
                                    {{demande.user_transportor?.tel_user}}<br /><br />
                                    <!-- {{demande.destination_cmd}}<br /><br /> -->

                                    <strong>La dernière connexion: </strong><br />
                                    {{demande.user_transportor?.lastcnx_user | date:'d MMM y'}}<br /><br />

                                    <strong>Date de la demande: </strong><br />
                                    {{demande.action_date_course | date:'d MMM y HH:mm'}}<br />
                                    <div class="badge badge-{{statusDemandes[demande.etat_course]?.color}}">
                                        {{statusDemandes[demande.etat_course]?.label}}
                                    </div>

                                    
                                    <br><div class="widget-subheading"
                                    *ngIf="demande?.flux != null && demande?.flux != ''">
                                    <b>{{demande?.flux}}</b>
                                </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>



                            <!-- <div class="row mt-4">
                                <div class="col-lg-8">
                                    <div class="section-title">Mode de livraison</div>
                                    <p class="section-lead">
                                        À domicile
                                        <br />→ {{cmd.destination_cmd}}
                                    </p>
                                </div>
                                <div class="col-lg-4 text-right">
                                    <div class="invoice-detail-item">
                                        <div class="invoice-detail-name">Prix des article(s):
                                            {{cmd.total_ca_cmd |
                                            number:'0.3'}} TND</div>
                                        <div class="invoice-detail-name">Frais de livraison:
                                            {{cmd.frais_livraison | number:'0.3'}} TND
                                        </div>
                                        <div class="invoice-detail-value">Montant total:
                                            {{cmd.total_ca_cmd+cmd.frais_livraison |
                                            number:'0.3'}} TND
                                        </div>
                                    </div>

                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="d-block text-center card-footer" *ngIf="demande.isPlanified == true">
                        <div class="text-center"><b>Transport planifié le {{demande?.planificationDate | date:'d MMM y
                                HH:mm'}}</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-6">
        <div class="row">
            <div class="col-lg-6">
                <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                        <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg bg-primary"></div>
                            <i class="pe-7s-map-marker text-primary"></i>
                        </div>
                        <div class="widget-numbers size-heading">Origine de la demande</div>
                        <div class="widget-subheading">{{demande?.user_location?.label_target}}</div>
                        <div class="widget-heading">{{demande?.user_location?.main_target}}</div>
                        <div class="widget-heading"><b>{{demande?.user_location?.sencondary_target}}</b></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                        <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg bg-danger"></div>
                            <i class="pe-7s-map-2 text-danger"></i>
                        </div>
                        <div class="widget-numbers size-heading">Déstination de la demande</div>
                        <div class="widget-subheading">{{demande?.user_destination?.label_target}}</div>
                        <div class="widget-heading">{{demande?.user_destination?.main_target}}</div>
                        <div class="widget-heading"><b>{{demande?.user_destination?.sencondary_target}}</b></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card mb-3 widget-chart">
                    <div class="widget-chart-content">
                        <div class="icon-wrapper rounded-circle">
                            <div class="icon-wrapper-bg bg-primary"></div>
                            <i class="pe-7s-cash text-primary"></i>
                        </div>
                        <div class="widget-numbers"><strong style="color: tomato;">{{demande?.total_price | number:'0.3'}} DT</strong></div>
                        <div class="widget-heading">
                            Type de service: <h2>{{demande?.type_vehicule}}</h2>
                        </div>
                        <div class="widget-heading">Prix compteur: <b>{{demande?.infosCourse?.real_tarif_corse | number:'0.3'}}</b></div>
                        <div class="widget-heading">Prix de la course: <strong>{{demande?.price_course | number:'0.3'}} TND</strong></div>
                        <div class="widget-heading">Prix bagage: <strong>{{demande?.unity | number:'0.3'}} TND</strong></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-6 card mb-3">
        <div class="row">
            <div #map id="map-canvas"></div>
            <div class="map-loader-container" *ngIf="!mapLoaded">
                <div class="map-loader"></div>
            </div>
        </div>
    </div> -->
</div>

<!-- <div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commentaires
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" (click)="addAppel(demande._id)" style="margin-bottom: 5px;"
                            class="btn btn-danger btn-sm" id="phone" tabindex="4"
                            [class]="(demande.appel_by != null) ? 'btn-success' : 'btn-danger'">
                            <i class="fa fa-phone"></i>
                        </button>&nbsp;&nbsp;
                        <button type="button" id="PopoverCustomT-1"
                            class="btn btn-warning btn-sm">{{demande?.comments?.length}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="chat-container">
                    <ul class="chat-box chatContainerScroll">
                        <div *ngFor="let comment of demande.comments, let index = index">

                            <li class="chat-right" *ngIf="comment?.type_user == 'client'">
                                <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                        class="fa fa-check-circle"></span></div>
                                <div class="chat-text">{{comment.content}}</div>
                                <div class="chat-avatar">
                                    <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}}">
                                    </ngx-avatar>
                                    <div class="chat-name">{{comment.name_user}}</div>
                                </div>
                            </li>

                            <li class="chat-left" *ngIf="comment?.type_user !== 'client'">
                                <div class="chat-avatar">
                                    <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}} ">
                                    </ngx-avatar>
                                    <div class="chat-name">{{comment.name_user}}</div>
                                </div>
                                <div class="chat-text">{{comment.content}}</div>
                                <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span
                                        class="fa fa-check-circle"></span></div>
                            </li>
                        </div>
                    </ul>
                </div>
                <div class="form-group mt-3 mb-0">
                    <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addComment(comment);">
                        <div class="form-row">
                            <div class="col-md-1">
                                <div class="position-relative form-group">
                                    <ngx-avatar width="42" class="rounded-circle"
                                        name="{{user?.prenom_user + ' ' + user?.nom_user}}">
                                    </ngx-avatar>
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="position-relative form-group">
                                    <textarea name="comment" id="comment" class="form-control" rows="2"
                                        placeholder="Ajouter un commentaire..." #comment ngModel></textarea>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="position-relative form-group">
                                    <button type="submit" (click)="addComment(comment)"
                                        class="form-control btn btn-success"><i class="pe-7s-paper-plane"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</div> -->

<div class="row">
    <div class="col-md-7">
        <div class="main-card mb-3 card">
            <div class="card-header">Propositions chauffeur
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Details <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="demande.driver_responses?.length === 0">
                        <h5 class="text-center">Aucune proposition affecté dans la
                            demande
                        </h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                        *ngIf="demande.driver_responses?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-left">Transportor</th>
                                <th class="text-center">Distance</th>
                                <th class="text-center">Durée</th>
                                <th class="text-center">Etat<br>de la<br>response</th>
                                <th class="text-center">Proposition</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let response of demande.driver_responses, let index = index">
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + response?.driver_id?.user_transportor?.profile_user}}"
                                                        alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                    {{trans.user_transportor.nom_user}}">
                                                    </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="goToNewTab(response?.driver_id?._id, '/detail-transportor')">
                                                    {{response?.driver_id?.user_transportor?.prenom_user}}
                                                    {{response?.driver_id?.user_transportor?.nom_user}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{response?.driver_id?.user_transportor?.email_user}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{response.distance}}
                                </td>
                                <td class="text-center">{{response.duree}}
                                </td>
                                <td class="text-center">{{response.etat_response}}</td>
                                <td class="text-center">
                                    {{response.proposition}} DT</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>

    <div class="col-md-5">
        <div class="main-card mb-3 card">
            <div class="card-header">Les chauffeurs prévu acctepter la demande
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Details <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <div class="budget-price justify-content-center" *ngIf="demande?.foreseenDrivers?.length === 0">
                        <h5 class="text-center">Aucune prévu acctepter dans la
                            demande
                        </h5>
                    </div>
                    <table class="align-middle mb-0 table table-borderless"
                        *ngIf="demande?.foreseenDrivers?.length > 0">
                        <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-left">Transportor</th>
                                <th class="text-center">Etat</th>
                                <th class="text-center">Notif<br>réçu</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let response of demande.foreseenDrivers, let index = index">
                                <tr [class]="(response.driver.is_blocked==true) ? 'bg-tr-danger' : ''">
                                    <td class="text-center text-muted">#{{index+1}}</td>
                                    <td>
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <div class="widget-content-left">
                                                        <img width="40" height="40" class="rounded-circle"
                                                            src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + response?.driver?.user_transportor?.profile_user}}"
                                                            alt="">
                                                    </div>
                                                </div>
                                                <div class="widget-content-left flex2">
                                                    <div class="widget-heading"
                                                        (click)="goToNewTab(response?.driver?._id, '/detail-transportor')">
                                                        {{response?.driver?.user_transportor?.prenom_user}}
                                                        {{response?.driver?.user_transportor?.nom_user}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{response?.driver?.user_transportor?.email_user}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        {{response?.driver?.user_transportor?.tel_user}}
                                                    </div>
                                                    <div class="widget-subheading opacity-7">
                                                        <b>Connexion: {{response?.driver?.user_transportor?.lastcnx_user
                                                            |
                                                            date:'MMM
                                                            d, y'}}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div
                                            [class]="(response?.driver?.onLine) ? 'badge badge-success' : 'badge badge-danger'">
                                            {{(response?.driver?.onLine) ? 'En ligne' : 'Hors ligne'}}</div>
                                    </td>
                                    <td class="text-center">{{(response?.is_sended != null && response?.is_sended ==
                                        true) ?
                                        'OUI' : 'NON'}}
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="row" *ngIf="demande?.acceptedBy != null">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Demande accepté par
                "<strong>{{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user + " " +
                    demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}</strong>"
                <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button type="button" id="PopoverCustomT-1" class="btn btn-danger btn-sm">Details <i
                                class="metismenu-icon pe-7s-angle-right"></i></button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Transporteur</th>
                                <th>Etat</th>
                                <th>Proposition
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle"
                                                        src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                        alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{trans.user_transportor.prenom_user}}
                                                    {{trans.user_transportor.nom_user}}">
                                                    </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-transportor')">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.email_user}}
                                                </div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="font-weight-600">
                                    {{demande?.etat_course}}
                                </td>
                                <td>{{demande?.total_price}} DT</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>