import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';

@Component({
  selector: 'app-list-courses',
  templateUrl: './list-courses.component.html',
  styleUrls: ['./list-courses.component.css']
})
export class ListCoursesComponent implements OnInit {

  subscription: Subscription;

  allDemandes: any = [];

  statusDemandes: any = [];
  status = ["Selectionnez status", "created", "accepted", "started", "arrived", "paied", "finish", "canceled"];
  selectedStatus: string = null

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  serviceList: any = []
  service: string = null

  vehList: any = []
  vehicule: string = null

  planified: any = "all";
  dateinPlan: any = null
  datefinPlan: any = null

  constructor(private transServices: CoursesService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatus().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )
    // this.listVehicule()
    // this.listServices()
    this.listDemandes()
  }

  async forcedCourse(idCourse) {
    await this.transServices.forcedCourse(idCourse).then(async (res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          if(res != null) {
            alert(res.msg)
            await this.listDemandes()
          }
        }
      });
  }

  async listDemandes(name?, numero?, datein?, datefin?) {
    await this.transServices.demandesTransportors(this.currentPage, this.selectedStatus,
      name, numero, datein, datefin, this.service, this.vehicule, this.planified, this.dateinPlan, this.datefinPlan).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          this.allDemandes = res.items;
          this.totalPages = res.totalPages
          this.currentPage = res.currentPage
          this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
          this.splicePages()
        }
      });
  }

  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  goToNewTab(idCmd, route) {
    this.transServices.goToNewTab(idCmd, route)
  }

  goToNewTabTaxi(idDriver, route) {
    this.transServices.goToNewTabDriver(idDriver, route)
  }

  selectChangeStatus(event: any) {
    if (event.target.value == "Selectionnez status") {
      this.selectedStatus = null
    } else {
      this.selectedStatus = event.target.value;
    }
  }

  async filterDemandes(name, numero, datein, datefin) {
    this.pages = []
    this.pagesDisplaied = []
    this.currentPage = 0
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  nextPage(name, numero, datein, datefin) {
    this.currentPage++
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  previousPage(name, numero, datein, datefin) {
    this.currentPage--
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

  selectPage(page, name, numero, datein, datefin) {
    this.currentPage = page
    this.listDemandes(name.value, numero.value, datein.value, datefin.value);
  }

}
