import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';

@Component({
  selector: 'app-pending-courses',
  templateUrl: './pending-courses.component.html',
  styleUrls: ['./pending-courses.component.css']
})
export class PendingCoursesComponent implements OnInit {

  statusDemandes: any = [];
  allDemandes: any = [];
  nbrDemande: number = 0

  subscription: Subscription;
  intervalId: number = null;

  constructor(private transServices: CoursesService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatus().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    this.listDemandes()

    const source = interval(5000);
    this.subscription = source.subscribe(val => {
      this.listDemandes()
    });
  }

  
  goToNewTab(idCmd, route) {
    this.transServices.goToNewTab(idCmd, route)
  }

  playSound() {
    var audio = new Audio();
      // audio.src = "http://www.schillmania.com/projects/soundmanager2/demo/mpc/audio/CHINA_1.mp3";
      audio.src = "https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7";
      audio.load();
      audio.play();
  }

  async listDemandes() {
    await this.transServices.demandesTransportors(0, "created",
      null, null, null, null, null, null).then((res: any) => {
        if (res) {
          // console.log("resssssssss : ")
          // console.log(res)
          this.allDemandes = res.items;
          // console.log(this.allDemandes.length != 0 && this.allDemandes.length!= this.nbrDemande)
          // console.log("this.allDemandes.length")
          // console.log(this.allDemandes.length)
          // console.log("this.nbrDemande")
          // console.log(this.nbrDemande)
          if(this.allDemandes.length != 0 && this.allDemandes.length!= this.nbrDemande) {
            console.log("sound")
            this.playSound();
          }
          this.nbrDemande = this.allDemandes.length
        }
      });
  }

}
