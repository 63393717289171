<!-- <app-image-viewer [imageURL]="imageURL" [open]="viewerOpen"
                                    (close)="viewerOpen = false"></app-image-viewer> -->

<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-note2 icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Transporteur
                <div class="page-title-subheading">Détail du transporteur.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="my-google-map" *ngIf="transporter?.isValidate == true">
    <google-map [options]="mapOptions" width="100%" [height]="300">


        <!-- <map-marker *ngFor="let m of markers; let i = index" [position]="{ lat: m.latitude, lng: m.longitude }"
            [label]="label" [title]="title" (mapClick)="openInfo(markerElem)">

        </map-marker> -->


        <map-marker #marker="mapMarker"
            [position]="{ lat: transporter.latitude, lng: transporter.longitude }"
            [options]="{icon: (transporter.onLine) ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}"
            >

            <!-- <map-info-window>
                <img width="40" height="40" class="rounded-circle"
                    src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + transporter?.user_transportor?.profile_user}}"
                    alt=""><br>
                <strong>{{transporter?.user_transportor?.prenom_user + " " + transporter?.user_transportor?.nom_user}}</strong><br>
                <strong>{{transporter?.user_transportor?.tel_user}}</strong><br>
                <strong>{{transporter?.info_vehicule?.type_camion}}</strong>
            </map-info-window> -->
        </map-marker>
    </google-map>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="tab-content" id="tab-content">
                <div class="main-card mb-12 card">
                    <div class="card-body">
                        <div id="commande" #commande>
                            <div class="row">
                                <div class="col-md-7">
                                    <!-- <div class="text space">
                                                                            <h4><strong>Transporteur</strong></h4>
                                                                        </div> -->
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <div
                                        [class]="(transporter.isValidate) ? 'badge badge-success' : 'badge badge-danger'">
                                        {{(transporter.isValidate) ? 'Validé' : 'Non validé'}}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-7">
                                    <strong>Transporteur:</strong><br />
                                    {{transporter.user_transportor?.prenom_user + " " +
                                    transporter?.user_transportor?.nom_user}}<br />
                                    {{transporter.user_transportor?.email_user}}<br />
                                    {{transporter.user_transportor?.tel_user}}<br /><br /><br />
                                    <!-- {{transporter.destination_cmd}}<br /> -->
                                    <strong>Gouvernorat: </strong><br />
                                    {{transporter?.governorate}}<br /><br />

                                    <strong>Date de l'inscription:</strong><br />
                                    {{transporter?.user_transportor?.dateinscrip_user | date:'dd/MM/yyyy'}}<br />
                                    <div [class]="(transporter.onLine) ? 'badge badge-success' : 'badge badge-danger'">
                                        {{(transporter?.onLine) ? 'En ligne' : 'Hors ligne'}}
                                    </div>
                                    <!-- <div class="text space">
                                                                            <qrcode print [width]="150" usesvg="true" [elementType]="'svg'"
                                                                                [qrdata]="cmd.qr_code" [errorCorrectionLevel]="'M'">
                                                                            </qrcode>
                                                                        </div> -->
                                </div>
                                <div class="col-md-5 text-md-right">
                                    <img width="170"
                                        src="{{env.transportorUsersAssetsBaseURL + transporter?.user_transportor?.profile_user}}"
                                        alt=""
                                        (click)="viewerOpenImage(env.transportorUsersAssetsBaseURL + transporter?.user_transportor?.profile_user)">
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Carte d'identité</div>
                                            <p class="section-lead">
                                                N° carte d'identé
                                                <br />→ {{transporter?.id_card?.num_id_card}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.cardTransportorAssetsBaseURL + transporter?.id_card?.recto}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.cardTransportorAssetsBaseURL + transporter?.id_card?.recto)">
                                        </div>
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.cardTransportorAssetsBaseURL + transporter?.id_card?.verso}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.cardTransportorAssetsBaseURL + transporter?.id_card?.verso)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Permis de conduire</div>
                                            <p class="section-lead">
                                                N° de permis
                                                <br />→ {{transporter?.driving_license?.num_driving_license}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.driverTransportorAssetsBaseURL + transporter?.driving_license?.recto}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.driverTransportorAssetsBaseURL + transporter?.driving_license?.recto)">
                                        </div>
                                        <div class="col-6 text-center">
                                            <img width="170"
                                                src="{{env.driverTransportorAssetsBaseURL + transporter?.driving_license?.verso}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.driverTransportorAssetsBaseURL + transporter?.driving_license?.verso)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Infos TAXI</div>
                                            <p class="section-lead">
                                                Type du taxi
                                                <br />{{transporter?.info_vehicule?.mark_taxi}} →
                                                {{transporter?.info_vehicule?.model_taxi}}
                                            </p>
                                            <p class="section-lead">
                                                Plaque d'immatriculation
                                                <br />→ {{transporter?.info_vehicule?.immatricule.part1}} Tun
                                                {{transporter?.info_vehicule?.immatricule.part2}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 text-center">
                                            <img width="170"
                                                src="{{env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.photo_vehicule}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.vehiculeTransportorAssetsBaseURL + transporter?.info_vehicule?.photo_vehicule)">
                                        </div>
                                        <div class="col-4 text-center">
                                            <img width="170"
                                                src="{{env.professionalCardAssetsBaseURL + transporter?.professional_card?.recto}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.professionalCardAssetsBaseURL + transporter?.professional_card?.recto)">
                                        </div>
                                        <div class="col-4 text-center">
                                            <img width="170"
                                                src="{{env.professionalCardAssetsBaseURL + transporter?.professional_card?.verso}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.professionalCardAssetsBaseURL + transporter?.professional_card?.verso)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="clearfix"></div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="section-title">Carte dexploit</div>
                                            <!-- <p class="section-lead">
                                                                                    Numéro de carte d'identification fiscale
                                                                                    <br />→ {{transporter?.patent?.num_patent}}
                                                                                </p> -->
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <img width="170"
                                                src="{{env.patentTransportorAssetsBaseURL + transporter?.exploit_card?.photo}}"
                                                alt=""
                                                (click)="viewerOpenImage(env.patentTransportorAssetsBaseURL + transporter?.exploit_card?.photo)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-block text-center card-footer">
                        <button
                            [class]="(transporter?.is_blocked != null && transporter?.is_blocked==true) ? 'btn btn-danger float-left' : 'btn btn-success float-left'"
                            (click)="blockTransportor()"><i class="metismenu-icon pe-7s-back"></i>
                            {{(transporter?.is_blocked != null && transporter?.is_blocked==true) ? 'Débloqué le
                            transporteur' : 'Bloqué le transporteur'}}
                        </button>

                        <button class="btn btn-success float-right text-white" (click)="validateTransportor()"><i
                                class="metismenu-icon pe-7s-check text-white"></i> Valider</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="transporter?.pendingDemandes != null && transporter?.pendingDemandes?.length > 0">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Les demandes en cours de {{"'" + transporter.user_transportor?.prenom_user + " " +
                transporter?.user_transportor?.nom_user + "'"}}
                <!-- <div class="btn-actions-pane-right">
                                                        <div role="group" class="btn-group-sm btn-group">
                                                            <button class="active btn btn-focus">Last Week</button>
                                                            <button class="btn btn-focus">All Month</button>
                                                        </div>
                                                    </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de<br>la course</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Détails<br>de<br>la course</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let demande of transporter.pendingDemandes, let index = index">
                            <tr>
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                    ? demande?.user_transportor?.profile_user : 'profile.jpg')}}" alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                                {{demande.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-taxi-driver')">
                                                    {{demande?.user_transportor?.prenom_user}}
                                                    {{demande?.user_transportor?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.user_transportor?.email_user}}</div>
                                                <div class="widget-subheading">
                                                    {{demande?.user_transportor?.tel_user}}</div>
                                                <div
                                                    [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                    {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                                <div class="widget-subheading"
                                                    *ngIf="demande?.flux != null && demande?.flux != ''">
                                                    <b>→ {{demande?.flux}}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{demande?.action_date_course | date:'d MMM y HH:mm'}}</td>
                                <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                    <strong>{{demande?.user_location?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                    <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                                </td>
                                <td>
                                    Type de service: <strong>{{demande?.type_vehicule}}</strong><br>
                                    Prix de la course: <strong>{{demande?.price_course | number:'0.3'}} TND</strong><br>
                                    Prix bagage: <strong>{{demande?.unity | number:'0.3'}} TND</strong><br>
                                    <b>Prix total: </b><strong style="color: tomato;">{{demande?.total_price | number:'0.3'}} TND
                                        DT</strong>
                                </td>
                                <!-- <td class="text-center">
                                <b>{{element.client?.datePickUp}}</b>
                            </td> -->
                                <td class="text-center">
                                    <div class="badge badge-{{statusDemandes[demande?.etat_course]?.color}}">
                                        {{statusDemandes[demande?.etat_course]?.label}}</div>
                                    <div *ngIf="demande?.etat_course == 'canceled'">
                                        <b>{{demande?.msg_returned_cmd}}</b><br>
                                        <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <!-- <button type="submit" (click)="addAppel(demande)" style="margin-bottom: 5px;"
                                        class="btn btn-lg btn-icon icon-righ btn-search" id="phone" tabindex="4"
                                        [class]="(demande.appel_by != null) ? 'btn-success' : 'btn-danger'">
                                        <i class="fa fa-phone"></i>
                                    </button>
                                    <button type="submit" (click)="addCommentsDemande(demande)"
                                        style="margin-bottom: 5px;"
                                        class="btn btn-warning btn-lg btn-icon icon-righ btn-search" id="search"
                                        tabindex="4">
                                        {{((demande?.comments != null) ? demande?.comments.length : 0) + " "}}<i
                                            class="pe-7s-paper-plane"></i>
                                    </button> -->
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button"
                                                (click)="goToNewTab(demande._id, '/details-course')"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details course
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="demande.isPlanified == true" style="background-color: yellowgreen;">
                                <td colspan="9" class="text-center"><b>Transport planifié le
                                        {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="transporter?.canceledDemandes != null && transporter?.canceledDemandes?.length > 0">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Les demandes annulé de {{"'" + transporter.user_transportor?.prenom_user + " " +
                transporter?.user_transportor?.nom_user + "'"}}
                <!-- <div class="btn-actions-pane-right">
                                                        <div role="group" class="btn-group-sm btn-group">
                                                            <button class="active btn btn-focus">Last Week</button>
                                                            <button class="btn btn-focus">All Month</button>
                                                        </div>
                                                    </div> -->
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de<br>la course</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Détails<br>de<br>la course</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let demande of transporter.canceledDemandes, let index = index">
                            <tr>
                                <td class="text-center text-muted">#{{index+1}}</td>
                                <td>
                                    <div class="widget-content p-0">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left mr-3">
                                                <div class="widget-content-left">
                                                    <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                    ? demande?.user_transportor?.profile_user : 'profile.jpg')}}" alt="">
                                                    <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                                {{demande.user_transportor.nom_user}}">
                                                </ngx-avatar> -->
                                                </div>
                                            </div>
                                            <div class="widget-content-left flex2">
                                                <div class="widget-heading"
                                                    (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-taxi-driver')">
                                                    {{demande?.user_transportor?.prenom_user}}
                                                    {{demande?.user_transportor?.nom_user}}</div>
                                                <div class="widget-subheading opacity-7">
                                                    {{demande?.user_transportor?.email_user}}</div>
                                                <div class="widget-subheading">
                                                    {{demande?.user_transportor?.tel_user}}</div>
                                                <div
                                                    [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                    {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                                <div class="widget-subheading"
                                                    *ngIf="demande?.flux != null && demande?.flux != ''">
                                                    <b>→ {{demande?.flux}}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">{{demande?.action_date_course | date:'d MMM y HH:mm'}}</td>
                                <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                    <strong>{{demande?.user_location?.sencondary_target}}</strong>
                                </td>
                                <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                    <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                                </td>
                                <td>
                                    Type de service: <strong>{{demande?.type_vehicule}}</strong><br>
                                    Prix de la course: <strong>{{demande?.price_course | number:'0.3'}} TND</strong><br>
                                    Prix bagage: <strong>{{demande?.unity | number:'0.3'}} TND</strong><br>
                                    <b>Prix total: </b><strong style="color: tomato;">{{demande?.total_price | number:'0.3'}} TND
                                        DT</strong>
                                </td>
                                <!-- <td class="text-center">
                                <b>{{element.client?.datePickUp}}</b>
                            </td> -->
                                <td class="text-center">
                                    <div class="badge badge-{{statusDemandes[demande?.etat_course]?.color}}">
                                        {{statusDemandes[demande?.etat_course]?.label}}</div>
                                    <div *ngIf="demande?.etat_course == 'canceled'">
                                        <b>{{demande?.msg_returned_cmd}}</b><br>
                                        <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <!-- <button type="submit" (click)="addAppel(demande)" style="margin-bottom: 5px;"
                                        class="btn btn-lg btn-icon icon-righ btn-search" id="phone" tabindex="4"
                                        [class]="(demande.appel_by != null) ? 'btn-success' : 'btn-danger'">
                                        <i class="fa fa-phone"></i>
                                    </button>
                                    <button type="submit" (click)="addCommentsDemande(demande)"
                                        style="margin-bottom: 5px;"
                                        class="btn btn-warning btn-lg btn-icon icon-righ btn-search" id="search"
                                        tabindex="4">
                                        {{((demande?.comments != null) ? demande?.comments.length : 0) + " "}}<i
                                            class="pe-7s-paper-plane"></i>
                                    </button> -->
                                    <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                        <button class="btn-wide btn btn-info">Actions</button>
                                        <button type="button" ngbDropdownToggle
                                            class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                                class="sr-only">Actions</span></button>
                                        <div ngbDropdownMenu>
                                            <button type="button"
                                                (click)="goToNewTab(demande._id, '/details-taxi-driver')"
                                                tabindex="0" class="dropdown-item">
                                                <i class="metismenu-icon pe-7s-news-paper"
                                                    style="margin-right: 10px;"></i>Details course
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="demande.isPlanified == true" style="background-color: yellowgreen;">
                                <td colspan="9" class="text-center"><b>Transport planifié le
                                        {{demande?.planificationDate | date:'d MMM y HH:mm'}}</b></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>