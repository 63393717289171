import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUserWassalni')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  // saveToken(fcmtoken) {
  //   var token = localStorage.getItem('token-wassalni')
  //   return new Promise((solve) => {
  //     var decoded = jwt_decode(token);

  //     this.http.post(environment.apiSaveTokenPH, {
  //       fcmtoken: fcmtoken,
  //       userid: decoded["id"]
  //     }).subscribe((res: any) => {
  //       // console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
  //       // console.log(res);
  //       if (res) {
  //           solve(res.response);
  //       }
  //     })
  //   });
  // }

  isConnected() {
    var token = localStorage.getItem('token-wassalni')
    // console.log("token : " + token)
    if (token)
      return true;

    return false
  }

  getUserInfos() {
    var token = localStorage.getItem('token-wassalni')

    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);
        // console.log("decoded")
        // console.log(decoded)
        this.http.post(environment.apiInfosUser, { "teamid": decoded["id"] }).subscribe((res: any) => {
          // console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          // console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });

  }

  saveUserCreds(usercreds) {
    localStorage.setItem('token-wassalni', JSON.stringify(usercreds.token));
    localStorage.setItem('currentUserWassalni', JSON.stringify(usercreds));
  }

  login(creds) {
    //console.log("creds");
    //console.log(creds);
    return new Promise((solve) => {
      this.http.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        // console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        // console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            this.currentUserSubject.next(res.data);
            solve(true);
          } else {
            solve(false);
          }
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem('token-wassalni');
    localStorage.removeItem('currentUserWassalni');
    this.currentUserSubject.next(null);
  }
}
