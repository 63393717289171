import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(private http: HttpClient,
    private router: Router) { }

    async statisticFluxCmds() {
      return await new Promise((slv) => {
        this.http.post(environment.apiStatisticsFluxCmds, { }).subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
      });
    }

    async allTypeTaxis() {
      return await new Promise((slv) => {
        this.http.post(environment.apiTypeTaxi, {}).subscribe(
          (data: any) => {
            // this.socket.emit('demandes', 'Hello there from Angular.');
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
      });
    }

    async getMarkerTaxis(name, numtel, vehicule) {
      return await new Promise((slv) => {
        this.http.post(environment.apiFilterMarkersTaxis, {
          name,
          numtel,
          vehicule,
        }).subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
      });
    }

    async forcedCourse(idCourse) {
      return await new Promise((slv) => {
        this.http.post(environment.apiForceCourse, {
          idCourse: idCourse
        }).subscribe(
          (data: any) => {
            if (data) {
              slv(data);
            } else slv(null);
          },
          (error) => {
            slv(null);
          }
        );
      });
    }

    async demandesTransportors(page, status, name?, numero?, datein?, datefin?, service?, vehicule?, planified?, dateinPlan?, datefinPlan?) {
      return await new Promise((slv) => {
        this.http.post(environment.apiAllDemandes, {
          page,
          status: status,
          name: name,
          numtel: numero,
          startDate: datein,
          endDate: datefin,
          service,
          vehicule,
          planified,
          startDatePlan: dateinPlan,
          endDatePlan: datefinPlan,
        }).subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
      });
    }

    async getPendingDemandes() {
      return await new Promise((slv) => {
        this.http.post(environment.apiPendingDemandes, {}).subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(null);
          },
          (error) => {
            slv(null);
          }
        );
      });
    }

    async getOneDemande(demandeId) {
      return await new Promise((slv) => {
        this.http.post(environment.apiOneDemande, { "idCourse": demandeId }).subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
      });
    }

  getLabeledStatus() {
    // ["pending", "validated", "preparing", "submited", "transported", "arrived", "delivered", "returned", "refused", "canceled"],
    return new Promise((slv) => {
      this.http.post(environment.apiStatus, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsByStatus(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatus, {
        client: idClient
      }).subscribe(
        (data: any) => {
          // console.log("dataaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsClientByStatus(page, idClient, status) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_course: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusAndClient, {
        page,
        client: idClient,
        status: listStatus
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async getTransportors() {
    return await new Promise((slv) => {
      this.http.post(environment.apiAllTansportors, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getOneTrans(idTrans) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneTansportor, { "idTrans": idTrans }).subscribe(
        (data: any) => {
          console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  validateTransportor(idTrans) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiValidateTansportor, { "iddriver": idTrans }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data) {
            slv(data);
          }// else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  async blockedTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiBlockedTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async deblockedTansportor(idDriver) {
    return await new Promise((slv) => {
      this.http.post(environment.apiDeblockedTansportor, {
        idDriver: idDriver
      }).subscribe(
        (data: any) => {
          // console.log("dddddddddddddd data")
          // console.log(data)
          if (data) {
            slv(data);
          } //else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  goToNewTab(idCmd, route) {
    const queryParams = {
      demande: idCmd
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

  goToNewTabDriver(idDriver, route) {
    const queryParams = {
      transportor: idDriver
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

}
