import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';
import { TeamsService } from 'src/app/services/teams-wassalni/teams.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-course',
  templateUrl: './details-course.component.html',
  styleUrls: ['./details-course.component.css']
})
export class DetailsCourseComponent implements OnInit {

  user: any = null

  demande: any = {}
  demandeID: string = '';
  env: any;

  statusDemandes: any = [];

  // mapLoaded: boolean;
  // map: google.maps.Map;
  // center: google.maps.LatLngLiteral;

  // source: google.maps.LatLngLiteral;
  // destination: google.maps.LatLngLiteral;

  // options: google.maps.MapOptions = {
  //   mapTypeId: google.maps.MapTypeId.ROADMAP,
  //   scrollwheel: true,
  //   disableDefaultUI: true,
  //   disableDoubleClickZoom: true,
  //   zoom: 12
  // }

  // ds: google.maps.DirectionsService;
  // dr: google.maps.DirectionsRenderer;

  constructor(private route: ActivatedRoute,
    private teamService: TeamsService,
    private transServices: CoursesService) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatus().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    this.getInfosUser()

    this.env = environment
    this.route.queryParams.subscribe(async (params) => {
      this.demandeID = params.demande;
      this._fetchDemande();
      // console.log(this.cmd);
    });
  }

  async getInfosUser() {
    await this.teamService.getUserInfos().then((res: any) => {
      // console.log("getUserInfos");
      // console.log(res);
      if (res) {
        this.user = res;
      }
    });
  }

  async _fetchDemande() {
    await this.transServices.getOneDemande(this.demandeID).then((d) => {
      console.log("ddddddddddddddddd")
      console.log(d)
      if (d) {
        this.demande = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/demandes-transportors';
      }
    });

    // this._initMap();
  }

}
