import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ImageViewerComponent } from 'src/app/components/shared/dialogs/image-viewer/image-viewer.component';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-details-taxi-driver',
  templateUrl: './details-taxi-driver.component.html',
  styleUrls: ['./details-taxi-driver.component.css']
})
export class DetailsTaxiDriverComponent implements OnInit {

  statusDemandes: any = [];

  transporter: any = {}
  idTrans: string = '';
  env: any;

  mapOptions: google.maps.MapOptions = {
    center: { lat: 36.841177, lng: 10.211049 },
    zoom: 10
  }

  constructor(private route: ActivatedRoute,
    private transServices: CoursesService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.transServices.getLabeledStatus().then(
      (lst) => {
        this.statusDemandes = lst
      }
    )

    this.env = environment
    this.route.queryParams.subscribe((params) => {
      this.idTrans = params.transportor;
      this._fetchTransportor();
      // console.log(this.cmd);
    });
  }

  viewerOpenImage(src) {
    window.scrollTo(0, 0);
    const confirmDialog = this.dialog.open(ImageViewerComponent, {
      data: {
        viewerOpen: true,
        imageURL: src,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
      }
    });
  }

  _fetchTransportor() {
    // console.log(cid);
    this.transServices.getOneTrans(this.idTrans).then((d) => {
      // console.log("dddddddddddddd")
      //     console.log(d)
      if (d) {
        this.transporter = d;
        if (this.transporter != null && this.transporter.isValidate == true) {
          this.mapOptions = {
            center: { lat: this.transporter.latitude, lng: this.transporter.longitude },
            zoom: 10
          }
        }
      } else {
        alert('Aucune transporter valide');
        location.href = '/taxi-drivers';
      }
    });
  }

  validateTransportor() {
    this.transServices.validateTransportor(this.idTrans).then((data: any) => {
      console.log("dddddddddddddd")
      console.log(data)

      if (data && data.response && data.data) {
        // console.log("dddddddddddddd")
        // console.log(d)
        alert("Le transporteur est validé avec succé");
        this.transporter = data.data;
      } else {
        alert(data.msg);
        // location.href = '/transportors';
      }
    });
  }

  blockTransportor() {
    if (this.transporter != null && this.transporter.is_blocked != null) {
      if (this.transporter.is_blocked == false) {
        this.transServices.blockedTansportor(this.idTrans).then((data: any) => {
          console.log("dddddddddddddd data")
          console.log(data)
          if (data && data.response && data.data) {
            this.transporter = data.data;
          } else {
            alert(data.msg);
          }
        });
      } else {
        this.transServices.deblockedTansportor(this.idTrans).then((data: any) => {
          console.log("dddddddddddddd")
          console.log(data)
          if (data && data.response && data.data) {
            this.transporter = data.data;
          } else {
            alert(data.msg);
          }
        });
      }
    }
  }

}
