<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Dashboard
                <div class="page-title-subheading">Il s'agit d'un tableau de bord créé à l'aide
                    d'éléments et de composants intégrés.
                </div>
            </div>
        </div>
        <!-- <div class="page-title-actions">
                            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                                class="btn-shadow mr-3 btn btn-dark">
                                <i class="fa fa-star"></i>
                            </button>
                            <div class="d-inline-block dropdown">
                                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    class="btn-shadow dropdown-toggle btn btn-info">
                                    <span class="btn-icon-wrapper pr-2 opacity-7">
                                        <i class="fa fa-business-time fa-w-20"></i>
                                    </span>
                                    Buttons
                                </button>
                                <div tabindex="-1" role="menu" aria-hidden="true"
                                    class="dropdown-menu dropdown-menu-right">
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-inbox"></i>
                                                <span>
                                                    Inbox
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-book"></i>
                                                <span>
                                                    Book
                                                </span>
                                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="javascript:void(0);" class="nav-link">
                                                <i class="nav-link-icon lnr-picture"></i>
                                                <span>
                                                    Picture
                                                </span>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                                <i class="nav-link-icon lnr-file-empty"></i>
                                                <span>
                                                    File Disabled
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
    </div>
</div>

<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['created'] }">
        <div class="card mb-3 widget-content bg-warning">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Créé</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.validated?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['accepted'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Accepté</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.transporting_depot?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['started'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Commencé</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.prepared?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['arrived'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Arrivé</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.inProgress?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['finish']}">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Terminé</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.demandePayment?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['paied'] }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Payée</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.paied?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['canceled'] }">
        <div class="card mb-3 widget-content bg-secondary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Annulée</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.canceled?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['paied'] }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">Livré payés</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.paied?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">La liste des courses actuelles
                <div class="btn-actions-pane-right">
                    <button type="button" id="PopoverCustomT-1"
                        class="btn btn-danger btn-sm">{{cmdList.length}}</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="align-middle mb-0 table table-borderless table-hover">
                    <thead>
                        <tr>
                            <!-- <th class="text-center">#</th> -->
                            <th>Utilisateurs</th>
                            <!-- <th class="text-left">Référence</th> -->
                            <!-- <th class="text-center">Date Création</th> -->
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Créé</th>
                            <th class="text-center">Accepté</th>
                            <th class="text-center">Commencé</th>
                            <th class="text-center">Arrivé</th>
                            <th class="text-center">Terminé</th>
                            <th class="text-center">Annulée</th>
                            <!-- <th class="text-center">Commentaire</th>
                            <th class="text-center">Affecté à</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">prévu</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let demande of cmdList, let index = index" 
                            class="border-tr" (click)="goToNewTab(demande._id, '/details-course')">
                            <!-- <td class="text-center text-muted">#345</td> -->
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                ? demande?.user_transportor?.profile_user : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                            {{demande.user_transportor.nom_user}}">
                                            </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading"
                                                (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-taxi-driver')">
                                                {{demande?.user_transportor?.prenom_user}}
                                                {{demande?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{demande?.user_transportor?.email_user}}</div>
                                            <div class="widget-subheading">
                                                {{demande?.user_transportor?.tel_user}}</div>
                                            <div
                                                [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                            <div class="widget-subheading"
                                                *ngIf="demande?.flux != null && demande?.flux != ''">
                                                <b>→ {{demande?.flux}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <!-- <td class="text">
                                <a (click)="goToNewTab(cmd.element._id, '/detail-cmd')">{{cmd.element.ref_facture_cmd}}</a>
                            </td> -->
                            <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                <strong>{{demande?.user_location?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">
                                <div *ngIf="demande.etat_course == 'created'"
                                    class="badge badge-{{statusCmds[demande.etat_course].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{demande.action_date_course | date:'d MMM y'}}<br>
                                <b>{{demande.action_date_course | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="demande.etat_course == 'accepted'"
                                    class="badge badge-{{statusCmds[demande.etat_course].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{demande?.acceptingDate | date:'d MMM y'}}<br>
                                <b>{{demande?.acceptingDate | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="demande.etat_course == 'started'"
                                    class="badge badge-{{statusCmds[demande.etat_course].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{demande?.startingDate | date:'d MMM y'}}<br>
                                <b>{{demande?.startingDate | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="demande.etat_course == 'arrived'"
                                    class="badge badge-{{statusCmds[demande.etat_course].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{demande?.arrivingDate | date:'d MMM y'}}<br>
                                <b>{{demande?.arrivingDate | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="demande.etat_course == 'finish'"
                                    class="badge badge-{{statusCmds[demande.etat_course].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{demande?.finishDate | date:'d MMM y'}}<br>
                                <b>{{demande?.finishDate | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="demande.etat_course == 'canceled'"
                                    class="badge badge-{{statusCmds[demande.etat_course].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{demande?.canceledDate | date:'d MMM y'}}<br>
                                <b>{{demande?.canceledDate | date:'HH:mm'}}</b>
                            </td>
                            <!-- <td class="text-center">
                                <span class="badge badge-pill badge-danger"
                                    (click)="listComments(cmd)">{{cmd.element?.comments?.length}}</span>
                            </td>
                            <td class="text-center" style="color: red;">
                                <strong>{{cmd.element.transport_affect[cmd.element.transport_affect.length-1]?.transporter_id?.nom_prenom_tracking}}</strong>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    {{statusCmds[cmd.element.etat_cmd].label}}</div>
                            </td>
                            <td class="text-center">{{cmd.planned.plannedTime | date:'d MMM'}}<br>
                                <b>{{cmd.planned.plannedTime | date:'HH:mm'}}</b>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                <button class="btn-wide btn btn-success">Save</button>
                            </div> -->
        </div>
    </div>
</div>
