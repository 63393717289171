import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { AvatarModule } from 'ngx-avatar';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { ArchitectUIState, rootReducer } from './ThemeOptions/store';
import { TopComponent } from './components/shared/top/top.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { TaxiDriversComponent } from './pages/taxi-drivers/taxi-drivers/taxi-drivers.component';
import { DetailsTaxiDriverComponent } from './pages/taxi-drivers/details-taxi-driver/details-taxi-driver.component';
import { ImageViewerComponent } from './components/shared/dialogs/image-viewer/image-viewer.component';
import { DetailsCourseComponent } from './pages/courses/details-course/details-course.component';
import { PendingCoursesComponent } from './pages/courses/pending-courses/pending-courses.component';
import { ListCoursesComponent } from './pages/courses/list-courses/list-courses.component';
import { PositionsDriversComponent } from './pages/taxi-drivers/positions-drivers/positions-drivers.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
    },
    vertical: {
      position: 'top',
    },
  },
  theme: 'material',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BaseLayoutComponent,
    DashboardComponent,
    TopComponent,
    FooterComponent,
    SidebarComponent,
    StatusCommandesComponent,
    TaxiDriversComponent,
    DetailsTaxiDriverComponent,
    ImageViewerComponent,
    DetailsCourseComponent,
    PendingCoursesComponent,
    ListCoursesComponent,
    PositionsDriversComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    HttpClientModule,
    MatDialogModule,
    PerfectScrollbarModule,
    NgbModule,
    FormsModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    NotifierModule.withConfig(customNotifierOptions),
    AvatarModule
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    ConfigActions,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
