import { select } from '@angular-redux/store';
import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';
import { TeamsService } from 'src/app/services/teams-wassalni/teams.service';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;

  // @ViewChild('native') native: ElementRef;
  isConnected = false
  // allComments: any = []
  allDemandes: any = []
  // allPlanifiedDemandes: any = []
  
  currentUser: any = null

  constructor(
    private coursesService: CoursesService,
    private teamService: TeamsService,
    private router: Router,
    public globals: ThemeOptions,
    // private transServices: TransportorsService
    ) {
      this.teamService.currentUser.subscribe(x => this.currentUser = x);
      this.isConnected = this.teamService.isConnected()
  }

  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;
  
  subscription: Subscription;
  intervalId: number = null;

  // emptyReturn: number = 0

  async ngOnInit() {
    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    // await this.fetchComments()

    const source = interval(1000);
    this.subscription = source.subscribe(val => {
      this.fetchPendingDemandes()
      // this.listPendingReturns()
      // this.fetchPendingPlanifiedDemandes()
    });
  }

  // async listPendingReturns() {
    // await this.transServices.getPendingEmptyReturn().then((res: any) => {
    //   if (res) {
    //     this.emptyReturn = res;
    //   }
    // });
  // }

  dateDiff(fromDate) {
    if(fromDate){
      var dayDiff = Math.floor((Math.floor(Date.now()) - fromDate)/* / (1000 * 60)*/);
      var seconds = Math.floor((dayDiff / 1000) % 60);
      var minutes = Math.floor((dayDiff / (1000 * 60)) % 60);
      var hours = Math.floor((dayDiff / (1000 * 60 * 60)) % 24);

      var hoursStr = (hours < 10) ? "0" + hours : hours;
      var minutesStr = (minutes < 10) ? "0" + minutes : minutes;
      var secondsStr = (seconds < 10) ? "0" + seconds : seconds;

      return hoursStr + ":" + minutesStr// + ":" + secondsStr
      // return {
      //   dayDiff: dayDiff,
      //   dayDiffText: hoursStr + ":" + minutesStr + ":" + secondsStr
      // };
    }
  };

  alertPlanifiedDemande(fromDate, index) {
    var css = ""
    if(fromDate){
      var dayDiff = Math.floor((fromDate - Math.floor(Date.now())) / (1000 * 60 * 60));

      if(dayDiff < 24 && dayDiff > 5) {
        css = "bg-tr-warning"
      } else if(dayDiff < 5){
        css =  "bg-tr-danger"
      } else {
        css =  ""
      }
    }

    return (index % 2===1) ? ('dropdown-item ' + css) : ('dropdown-item dropdown-item-unread ' + css)
  }

  // async fetchComments() {
  //   await this.cmdsService.allComments().then((res: any) => {
  //     // console.log("fetchComments");
  //     // console.log(res);
  //     if (res) {
  //       this.allComments = res;
  //     }
  //   });
  // }

  async fetchPendingDemandes() {
    await this.coursesService.getPendingDemandes().then((res: any) => {
      // console.log("fetchComments");
      // console.log(res);
      if (res) {
        this.allDemandes = res;
      }
    });
  }

  // async fetchPendingPlanifiedDemandes() {
    // await this.transServices.getPlanifiedDemandes().then((res: any) => {
    //   // console.log("fetchPendingPlanifiedDemandes");
    //   // console.log(res);
    //   if (res) {
    //     this.allPlanifiedDemandes = res;
    //   }
    // });
  // }

  async deconnexion() {
    await this.teamService.deconnexion()
    // this.router.navigate(['/home'])
    window.location.reload()
    this.router.navigate(['/login'])
  }

  toggleSidebar() {
    // console.log("toggleSidebar")
    // console.log(this.globals.toggleSidebar)
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    // console.log("sidebarHover")
    // console.log(this.globals.sidebarHover)
    this.globals.sidebarHover = !this.globals.sidebarHover;
    // if(this.globals.sidebarHover) {
    //   this.globals.toggleSidebar = true
    //   this.globals.sidebarHover = false
    // }
  }

  toggleSidebarMobile() {
    // console.log("toggleSidebarMobile")
    // console.log(this.globals.toggleSidebarMobile)
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
    if(this.globals.toggleSidebarMobile) {
      this.globals.toggleHeaderMobile = false
    }
  }

  toggleHeaderMobile() {
    // console.log("toggleHeaderMobile")
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
    if(this.globals.toggleHeaderMobile) {
      this.globals.toggleSidebarMobile = false
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }
}
