import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TeamsService } from '../services/teams-wassalni/teams.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  msgErrorUsername: string = null;
  msgErrorPassword: string = null;
  msgErrorLogin: string = null;

  constructor(private teamService: TeamsService,
    private router: Router,) { }

  ngOnInit(): void {
    if (this.teamService.isConnected()) {
      this.router.navigate([''])
    }
  }

  initMsgError() {
    this.msgErrorUsername = null;
    this.msgErrorPassword = null;
    this.msgErrorLogin = null
  }

  authentification(username, password) {
    this.initMsgError();
    // console.log(username.value, password.value);
    if (username.value == "" /*|| !username.value.includes("@")*/ || password.value == "") {
      // alert("Veuillez remplir tous les champs.")
      // if (!username.value.includes("@")) {
      //   this.msgErrorUsername = "Email est requis et doit contenir '@'";
      // }
      if (username.value == "") {
          this.msgErrorUsername = "Email est requis";
        }
      if (password.value == "") {
        this.msgErrorPassword = "Le mot de passe est requis";
      }
    } else {
      this.teamService.login({ username: username.value, password: password.value }).then((res: any) => {
        // console.log("res login : ")
        // console.log(res)
        if (res) {
          location.href = '/';
        }
        else {
          this.msgErrorLogin = "Impossible d'authentifié"
          password.value = ""
        }
      })
    }

  }

}