<div [class]="'app-header header-shadow bg-strong-bliss header-text-light ' + (config$ | async).headerTheme" 
(mouseover)="sidebarHover()" (mouseout)="sidebarHover()">
    <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
            <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
            [ngClass]="{'is-active' : globals.toggleSidebarMobile}" 
            (click)="toggleSidebarMobile()">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div>
    <div class="app-header__menu">
        <span>
            <button class="btn-icon btn-icon-only btn btn-primary"
                [ngClass]="{'is-active' : globals.toggleHeaderMobile}" 
                (click)="toggleHeaderMobile()">
                <div class="btn-icon-wrapper">
                    <i class="fas fa-ellipsis-v"></i>
                </div>
            </button>
        </span>
    </div>
    <div class="app-header__content" [ngClass]="{'header-mobile-open' : globals.toggleHeaderMobile}" >
        <div class="app-header-left">
            <div class="search-wrapper active" *ngIf="globals?.toggleHeaderMobile==false">
                <div class="input-holder">
                    <input type="text" class="search-input" placeholder="Type to search">
                    <button class="search-icon"><span></span></button>
                </div>
                <!-- <button class="close"></button> -->
            </div>
            <!-- <ul class="header-menu nav">
                <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                        <i class="nav-link-icon fa fa-database"> </i>
                        Statistics
                    </a>
                </li>
                <li class="btn-group nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                        <i class="nav-link-icon fa fa-edit"></i>
                        Projects
                    </a>
                </li>
                <li class="dropdown nav-item">
                    <a href="javascript:void(0);" class="nav-link">
                        <i class="nav-link-icon fa fa-cog"></i>
                        Settings
                    </a>
                </li>
            </ul> -->
        </div>
        <div class="app-header-right">
            <div class="header-btn-lg pr-0">
                <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                        <!-- <a [routerLink]="['/invalid-return']" class="nav-link notification-toggle nav-link-lg">
                            <div class="ml-auto badge badge-pill" [class]="(emptyReturn > 0) ? 'badge-danger' : 'badge-success'">
                                {{emptyReturn}}</div>
                            <img width="42" class="rounded-circle" src="assets/images/empty-return.png" alt="">
                        </a> -->
                        <ul class="header-menu nav">
                            <li class="dropdown dropdown-list-toggle" ngbDropdown placement="bottom-right">
                                <a class="nav-link notification-toggle nav-link-lg" ngbDropdownToggle>
                                    <div class="ml-auto badge badge-pill" [class]="(allDemandes?.length > 0) ? 'badge-danger' : 'badge-success'">
                                        {{allDemandes?.length}}</div>
                                    <img width="42" class="rounded-circle" src="assets/images/pending-demandes.jpg" alt="">
                                </a>
                                <div class="dropdown-menu dropdown-list dropdown-menu-right" ngbDropdownMenu>
                                    <div class="dropdown-header">Courses en cours
                                        <div class="float-right">
                                            <!-- <a href="#">Mark All As Read</a> -->
                                        </div>
                                    </div>
                                    <div class="dropdown-list-content dropdown-list-icons scroll-menu">
                                        <div *ngFor="let demande of allDemandes, let index = index">
                                            <a [routerLink]="['/details-course']"
                                            [queryParams]="{ demande: demande._id }"
                                                [ngClass]="(index % 2===1)?'dropdown-item':'dropdown-item dropdown-item-unread'">
                                                <div class="dropdown-item-icon text-white">
                                                    <img style="width: 100%; height: 100%;" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                    ? demande?.user_transportor?.profile_user : 'profile.jpg')}}"
                                                        alt="">
                                                </div>
                                                <div class="dropdown-item-desc">
                                                    <div class="time text-primary">{{demande?.user_transportor?.prenom_user}}
                                                        {{demande?.user_transportor?.nom_user}}
                                                    </div>
                                                    {{demande?.user_transportor?.tel_user}}
                                                </div>
                                                <div class="ml-auto badge badge-pill badge-danger">
                                                    {{dateDiff(demande?.action_date_course) + " min"}}</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="dropdown-footer text-center">
                                        <a [routerLink]="['/pending-courses']">Courses (En cours)<i class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- <ul class="header-menu nav">
                            <li class="dropdown dropdown-list-toggle" ngbDropdown placement="bottom-right">
                                <a class="nav-link notification-toggle nav-link-lg" ngbDropdownToggle>
                                    <div class="ml-auto badge badge-pill" [class]="(allPlanifiedDemandes?.length > 0) ? 'badge-danger' : 'badge-success'">
                                        {{allPlanifiedDemandes?.length}}</div>
                                    <img width="42" class="rounded-circle" src="assets/images/planified_demande.png" alt="">
                                </a>
                                <div class="dropdown-menu dropdown-list dropdown-menu-right" ngbDropdownMenu>
                                    <div class="dropdown-header">Planifier
                                        <div class="float-right">
                                        </div>
                                    </div>
                                    <div class="dropdown-list-content dropdown-list-icons scroll-menu">
                                        <div *ngFor="let demande of allPlanifiedDemandes, let index = index">
                                            <a [routerLink]="['/details-course']"
                                            [queryParams]="{ demande: demande._id }" [class]="alertPlanifiedDemande(demande?.planificationDate, index)"
                                                >
                                                <div class="dropdown-item-icon text-white">
                                                    <img style="width: 100%; height: 100%;" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                    ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                    ? demande?.user_transportor?.profile_user : 'profile.jpg')}}"
                                                        alt="">
                                                </div>
                                                <div class="dropdown-item-desc">
                                                    <div class="time text-primary">{{demande?.user_transportor?.prenom_user}}
                                                        {{demande?.user_transportor?.nom_user}}
                                                    </div>
                                                    {{demande?.user_transportor?.tel_user}}<br>
                                                    Accepté: <b>{{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user + " " + 
                                                    demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}</b>
                                                </div>
                                                <div class="ml-auto badge badge-pill badge-info">
                                                    {{demande?.planificationDate | date:'d MMM y'}}<br>
                                                    {{demande?.planificationDate | date:'HH:mm'}}</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="dropdown-footer text-center">
                                        <a [routerLink]="['/planified-demandes']">Demandes (Planifier)<i class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul> -->
                        <!-- <ul class="header-menu nav">
                            <li class="dropdown dropdown-list-toggle" ngbDropdown placement="bottom-right">
                                <a class="nav-link notification-toggle nav-link-lg" ngbDropdownToggle>
                                    <i class="far fa-bell"></i>
                                </a>
                                <div class="dropdown-menu dropdown-list dropdown-menu-right" ngbDropdownMenu>
                                    <div class="dropdown-header">Notifications
                                        <div class="float-right">
                                            <a href="#">Mark All As Read</a>
                                        </div>
                                    </div>
                                    <div class="dropdown-list-content dropdown-list-icons scroll-menu">
                                        <div *ngFor="let cmd of allComments, let index = index">
                                            <a [routerLink]="['/detail-cmd']" [queryParams]="{ cmd: cmd._id }"
                                                [ngClass]="(index % 2===1)?'dropdown-item':'dropdown-item dropdown-item-unread'">
                                                <div class="dropdown-item-icon text-white">
                                                    <ngx-avatar width="42" class="rounded-circle"
                                                        name="{{cmd.comments[cmd.comments.length-1].name_user}} ">
                                                    </ngx-avatar>
                                                </div>
                                                <div class="dropdown-item-desc">
                                                    <div class="time text-primary">{{
                                                        cmd.comments[cmd.comments.length-1].name_user }}
                                                    </div>
                                                    {{cmd.comments[cmd.comments.length-1].content}}
                                                </div>
                                                <div class="ml-auto badge badge-pill badge-danger">
                                                    {{cmd.comments.length}}</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="dropdown-footer text-center">
                                        <a href="#">View All <i class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </li>
                        </ul> -->
                        <div class="widget-content-left">
                            <div class="btn-group margin-icon-user" ngbDropdown placement="bottom-right">
                                <a class="btn btn-link p-0 mr-2" ngbDropdownToggle>
                                    <!-- <img width="42" class="rounded-circle" src="assets/images/avatars/1.jpg" alt=""> -->
                                    <ngx-avatar class="rounded-circle" name="{{currentUser.prenom + ' ' + currentUser.nom}}">
                                        <!-- <i class="fa fa-angle-down ml-2 opacity-8"></i> -->
                                    </ngx-avatar>
                                </a>
                                <div ngbDropdownMenu>
                                    <ul class="nav flex-column">
                                        <li class="nav-item">
                                            <div class="nav-link">
                                                <!-- <i class="metismenu-icon "></i> -->
                                                <i class="nav-link-icon pe-7s-lock"></i>
                                                <!-- <span> -->
                                                <button type="button" tabindex="0" class="dropdown-item"
                                                    (click)="deconnexion()">Déconnexion</button>
                                                <!-- </span> -->
                                                <!-- <div class="ml-auto badge badge-pill badge-secondary">86</div> -->
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="widget-content-left  ml-3 header-user-info">
                            <div class="widget-heading">
                                {{currentUser.prenom + ' ' + currentUser.nom}}
                            </div>
                            <div class="widget-subheading">
                                {{currentUser.profiles}}
                            </div>
                        </div>
                        <!-- <div class="widget-content-right header-user-info ml-3">
                            <button type="button" class="btn-shadow p-1 btn btn-danger btn-sm show-toastr-example">
                                <i class="fa text-white fa-calendar pr-1 pl-1"></i>
                            </button>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>