import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dataCmds: any = {}
  statusCmds: any = []

  cmdList: any = []
  subscription: Subscription;

  constructor(
    private cmdsService: CoursesService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.cmdsService.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )

    this.listCmdsByStatus()
    this.statisticFluxCmds()

    const source = interval(5000);
    this.subscription = source.subscribe(val => {
      this.listCmdsByStatus()
      this.statisticFluxCmds()
    });
  }

  async listCmdsByStatus() {
    //let userId = await this.clientService.getIdUser()
    this.cmdsService.allCmdsByStatus(null).then((res: any) => {
      console.log("res")
      console.log(res)
      if (res) {
        this.dataCmds = res;
      }
    });
  }

  async statisticFluxCmds() {
    await this.cmdsService.statisticFluxCmds().then((res: any) => {
      if (res) {
        console.log("cmdssssss res");
        console.log(res);
        this.cmdList = res;
      }

    });
  }

  goToNewTab(idCmd, route) {
    this.cmdsService.goToNewTab(idCmd, route)
  }

}
