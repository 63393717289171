// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

var baseURLAPI = 'https://logistic.marketbey.tn/api/v0/';

var assetsBaseURL = "https://assets.marketbey.tn/Wassali/";

export const environment = {
  production: false,

  // AUTH Teams WASSALI
  apiUsersAuth: baseURLAPI + "teams-ws/auth-wassalni",
  apiInfosUser: baseURLAPI + "teams-ws/infos-wassalni",

  // API COURSES
  apiStatus: baseURLAPI + 'taxi/liststatus',
  apiAllCmdsByStatus: baseURLAPI + 'taxi/cmds-all-status',
  apiAllCmdsByStatusAndClient: baseURLAPI + 'taxi/cmds-status-client',
  apiAllTansportors: baseURLAPI + 'taxi/taxi-drivers',
  apiOneTansportor: baseURLAPI + 'taxi/data-driver',
  // apiOneTansportor: baseURLAPI + 'taxi/data-driver',
  apiValidateTansportor: baseURLAPI + 'taxi/validate-transportor',
  apiBlockedTansportor: baseURLAPI + 'taxi/block-transportor',
  apiDeblockedTansportor: baseURLAPI + 'taxi/deblock-transportor',
  apiPendingDemandes: baseURLAPI + 'taxi/pending-demandes',
  apiOneDemande: baseURLAPI + 'taxi/get-course',
  apiAllDemandes: baseURLAPI + 'taxi/courses',
  apiTypeTaxi: baseURLAPI + 'taxi/type-taxis',
  apiFilterMarkersTaxis: baseURLAPI + 'taxi/filter-markers-driver',
  apiStatisticsFluxCmds: baseURLAPI + 'taxi/statistics-flux-cmds',
  apiForceCourse: baseURLAPI + 'taxi/force-course',
  
  
  // URL ASSETS IMAGE
  transportorUsersAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/users-trans/",
  driverTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-taxi/driver-licence/",
  cardTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-taxi/driver-card/",
  vehiculeTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-taxi/driver-vehicule/",
  professionalCardAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-taxi/driver-professional-card/",
  patentTransportorAssetsBaseURL: assetsBaseURL + "TRANSPORTOR/driver-taxi/driver-exploit-card/",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
