import { Component, OnInit } from '@angular/core';
import { CoursesService } from 'src/app/services/courses-taxi/courses.service';

@Component({
  selector: 'app-taxi-drivers',
  templateUrl: './taxi-drivers.component.html',
  styleUrls: ['./taxi-drivers.component.css']
})
export class TaxiDriversComponent implements OnInit {

  allTrans: any = [];
  allTransValid: any = [];
  allTransNotValid: any = [];
  nbrAllTrans: number = 0
  nbrTransHorsLine: number = 0
  nbrTransPending: number = 0
  tagTransportor: string = "Tous les transporteurs"

  villes = [
    {
      id: 1,
      label: "ALL",
      secondLabel: "ALL"
    },
    {
      id: 2,
      label: "Ariana",
      secondLabel: "Gouvernorat de l'Ariana"
    },
    {
      id: 3,
      label: 'Béja',
      secondLabel: "Gouvernorat de Béja",
      thirdLabel: 'Beja',
      fourLabel: "Gouvernorat de Beja"
    },
    {
      id: 4,
      label: 'Ben Arous',
      secondLabel: "Gouvernorat de Ben Arous"
    },
    {
      id: 5,
      label: 'Bizerte',
      secondLabel: "Gouvernorat de Bizerte"
    },
    {
      id: 6,
      label: 'Gabès',
      secondLabel: "Gouvernorat de Gabès",
      thirdLabel: 'Gabes',
      fourLabel: "Gouvernorat de Gabes"
    },
    {
      id: 7,
      label: 'Gafsa',
      secondLabel: "Gouvernorat de Gafsa"
    },
    {
      id: 8,
      label: 'Jendouba',
      secondLabel: "Gouvernorat de Jendouba"
    },
    {
      id: 9,
      label: 'Kairouan',
      secondLabel: "Gouvernorat de Kairouan"
    },
    {
      id: 10,
      label: 'Kasserine',
      secondLabel: "Gouvernorat de Kasserine"
    },
    {
      id: 11,
      label: 'Kébili',
      secondLabel: "Gouvernorat de Kébili",
      thirdLabel: 'Kebili',
      fourLabel: "Gouvernorat de Kebili"
    },
    {
      id: 12,
      label: 'Kef',
      secondLabel: "Gouvernorat de Kef"
    },
    {
      id: 13,
      label: 'Mahdia',
      secondLabel: "Gouvernorat de Mahdia"
    },
    {
      id: 14,
      label: 'Manouba',
      secondLabel: "Gouvernorat de Manouba"
    },
    {
      id: 15,
      label: 'Médenine',
      secondLabel: "Gouvernorat de Médenine",
      thirdLabel: 'Medenine',
      fourLabel: "Gouvernorat de Medenine"
    },
    {
      id: 16,
      label: 'Monastir',
      secondLabel: "Gouvernorat de Monastir"
    },
    {
      id: 17,
      label: 'Nabeul',
      secondLabel: "Gouvernorat de Nabeul"
    },
    {
      id: 18,
      label: 'Sfax',
      secondLabel: "Gouvernorat de Sfax"
    },
    {
      id: 19,
      label: 'Sidi Bouzid',
      secondLabel: "Gouvernorat de Sidi Bouzid"
    },
    {
      id: 20,
      label: 'Siliana',
      secondLabel: "Gouvernorat de Siliana"
    },
    {
      id: 21,
      label: 'Sousse',
      secondLabel: "Gouvernorat de Sousse"
    },
    {
      id: 22,
      label: 'Tataouine',
      secondLabel: "Gouvernorat de Tataouine"
    },
    {
      id: 23,
      label: 'Tozeur',
      secondLabel: "Gouvernorat de Tozeur"
    },
    {
      id: 24,
      label: 'Tunis',
      secondLabel: "Gouvernorat de Tunis"
    },
    {
      id: 25,
      label: 'Zaghouan',
      secondLabel: "Gouvernorat de Zaghouan"
    }
  ];
  selectedVille: any = null;

  constructor(private transServices: CoursesService) { }

  ngOnInit(): void {
    this.listTransportors()
  }

  selectChangeVille(event: any) {
    console.log("this.event.target.value")
    console.log(event.target.value)
    if (event.target.value == 1) {
      this.selectedVille = null;
    } else {
      var ville = this.villes.find(val => { return val.id == event.target.value })
      console.log("villeeeeeeeeee")
      console.log(ville)
      this.selectedVille = ville;
    }
  }

  filterCmds() {
    this.isValidTransportor()

    if(this.selectedVille != null) {
      this.allTrans = this.allTrans.filter
        (trans => (trans?.governorate == this.selectedVille.label
                  || trans?.governorate == this.selectedVille.secondLabel 
                  || (this.selectedVille.thirdLabel != null && trans?.governorate == this.selectedVille.thirdLabel)
                  || (this.selectedVille.fourLabel != null && trans?.governorate == this.selectedVille.fourLabel)));

    } else {
      this.allTrans = this.allTrans
    }
  }

  async listTransportors() {
    this.tagTransportor = "Tous les transporteurs"
    await this.transServices.getTransportors().then((res: any) => {
      if (res) {
        console.log("ressssssssssssss")
        console.log(res)
        this.allTrans = res;
        this.nbrAllTrans = this.allTrans.length
        this.allTransValid = this.filterTransportor(true)
        this.nbrTransHorsLine = this.getTransHorsLine(this.allTransValid)
        this.nbrTransPending = this.getTransPending(this.allTrans)
        this.allTransNotValid = this.filterTransportor(false)
      }
    });
  }

  getTransHorsLine(arrayTrans) {
    var array= arrayTrans.filter(trans => trans.onLine == false);
    return array.length
  }
  getTransPending(arrayTrans) {
    var array= arrayTrans.filter(trans => (trans.preteToValidate!=null && trans.preteToValidate == true));
    return array.length
  }

  filterTransportor(isValid, notblocked?) {
    return this.allTrans.filter(trans => trans.isValidate == isValid && trans.is_blocked == false);
  }

  isValidTransportor() {
    this.tagTransportor = "Transporteur validé"
    this.allTrans = this.allTransValid
  }

  isNotValidTransportor() {
    this.tagTransportor = "Transporteur non validé"
    this.allTrans = this.allTransNotValid
  }

  onLineTrans() {
    this.allTrans = this.allTransValid.filter(trans => trans.onLine == true);
  }

  offLineTrans() {
    this.allTrans = this.allTransValid.filter(trans => trans.onLine == false);
  }

  pendingTrans() {
    this.allTrans = this.allTrans.filter(trans => (trans.preteToValidate!=null && trans.preteToValidate == true));
  }

  goToNewTabTaxi(idDriver, route) {
    this.transServices.goToNewTabDriver(idDriver, route)
  }

}
