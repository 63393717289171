<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>
                {{statusCmd[statusCmds[0]]?.label}}
                <div class="page-title-subheading">Liste des commandes
                    <div class="badge badge-{{statusCmd[statusCmds[0]]?.color}}">
                        {{statusCmd[statusCmds[0]]?.label}}</div>
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" data-toggle="tooltip" title="Example Tooltip" data-placement="bottom"
                class="btn-shadow mr-3 btn btn-dark">
                <i class="fa fa-star"></i>
            </button>
            <div class="d-inline-block dropdown">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    class="btn-shadow dropdown-toggle btn btn-info">
                    <span class="btn-icon-wrapper pr-2 opacity-7">
                        <i class="fa fa-business-time fa-w-20"></i>
                    </span>
                    Buttons
                </button>
                <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-inbox"></i>
                                <span>
                                    Inbox
                                </span>
                                <div class="ml-auto badge badge-pill badge-secondary">86</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-book"></i>
                                <span>
                                    Book
                                </span>
                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">
                                <i class="nav-link-icon lnr-picture"></i>
                                <span>
                                    Picture
                                </span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a disabled href="javascript:void(0);" class="nav-link disabled">
                                <i class="nav-link-icon lnr-file-empty"></i>
                                <span>
                                    File Disabled
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Commandes
                <div class="btn-actions-pane-right">
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="listCmds?.length === 0">
                    <h5 class="text-center">Aucune commande {{statusCmds}} </h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="listCmds?.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de<br>la course</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Détails<br>de<br>la course</th>
                            <th class="text-center"
                                *ngIf='includes(["accepted", "started", "arrived", "finish"])'>
                                Accépté par</th>
                            <th class="text-center">Etat</th>
                            <th class="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let demande of listCmds, let index = index">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                ? demande?.user_transportor?.profile_user : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                            {{demande.user_transportor.nom_user}}">
                                            </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading"
                                                (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-taxi-driver')">
                                                {{demande?.user_transportor?.prenom_user}}
                                                {{demande?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{demande?.user_transportor?.email_user}}</div>
                                            <div class="widget-subheading">
                                                {{demande?.user_transportor?.tel_user}}</div>
                                            <div
                                                [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                            <div class="widget-subheading"
                                                *ngIf="demande?.flux != null && demande?.flux != ''">
                                                <b>→ {{demande?.flux}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{demande?.action_date_course | date:'d MMM y HH:mm'}}</td>
                            <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                <strong>{{demande?.user_location?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                            </td>
                            <td>
                                Type de service: <strong>{{demande?.type_vehicule}}</strong><br>
                                Prix de la course: <strong>{{demande?.price_course | number:'0.3'}} TND</strong><br>
                                Prix bagage: <strong>{{demande?.unity | number:'0.3'}} TND</strong><br>
                                <b>Prix total: </b><strong style="color: tomato;">{{demande?.total_price | number:'0.3'}} TND
                                    DT</strong>
                            </td>
                            <td *ngIf="demande?.acceptedBy != null">
                                <div>
                                    <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                        <li class="list-group-item">
                                            <div class="widget-content p-0">
                                                <div class="widget-content-wrapper">
                                                    <div class="widget-content-left mr-3">
                                                        <img width="40" height="40" class="rounded-circle"
                                                            src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + demande?.acceptedBy?.driver_id?.user_transportor?.profile_user}}"
                                                            alt="">
                                                        <!-- <ngx-avatar class="rounded-circle" name="{{driver?.travelerUser?.prenom_user}} {{driver?.travelerUser?.nom_user}}">
                                            </ngx-avatar> -->
                                                    </div>
                                                    <div class="widget-content-left">
                                                        <div class="widget-heading"
                                                            (click)="goToNewTab(demande?.acceptedBy?.driver_id?._id, '/detail-taxi-driver')">
                                                            {{demande?.acceptedBy?.driver_id?.user_transportor?.prenom_user}}
                                                            {{demande?.acceptedBy?.driver_id?.user_transportor?.nom_user}}
                                                        </div>
                                                        <div class="widget-subheading">
                                                            {{demande?.acceptedBy?.driver_id?.user_transportor?.tel_user}}
                                                        </div>
                                                    </div>
                                                    <!-- <div class="widget-content-right">
                                                        <div class="font-size-xlg text-muted">
                                                            <span>{{driver?.proposition}} DT</span>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmd[demande?.etat_course]?.color}}">
                                    {{statusCmd[demande?.etat_course]?.label}}</div>
                                <div *ngIf="demande?.etat_course == 'canceled'">
                                    <b>{{demande?.msg_returned_cmd}}</b><br>
                                    <b>{{demande?.canceledDate | date:'d MMM y HH:mm'}}</b>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="mb-2 mr-2 dropright btn-group" ngbDropdown placement="left-top">
                                    <button class="btn-wide btn btn-info">Actions</button>
                                    <button type="button" ngbDropdownToggle
                                        class="dropdown-toggle-split dropdown-toggle btn btn-info"><span
                                            class="sr-only">Actions</span></button>
                                    <div ngbDropdownMenu>
                                        <button type="button" (click)="goToNewTab(demande._id, '/details-course')" tabindex="0"
                                            class="dropdown-item">
                                            <i class="metismenu-icon pe-7s-news-paper"
                                                style="margin-right: 10px;"></i>Details course
                                        </button>
                                        <!-- <button type="button" tabindex="0" (click)="goToNewTab(cmd._id, '/facture-cmd')"
                                            class="dropdown-item"><i class="metismenu-icon pe-7s-print"
                                                style="margin-right: 10px;"></i>Facture</button> -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-block text-center card-footer" *ngIf="listCmds?.length > 0 && pages?.length > 1">
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li [ngClass]="(currentPage===0)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="previousPage()">
                                <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                                Précédent</a>
                        </li>

                        <div *ngFor="let p of pagesDisplaied">
                            <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                                <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p)">{{p+1}}</a>

                                <span *ngIf="currentPage===p" class="page-link">
                                    {{p+1}}
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                        </div>
                        <!-- <li class="page-item active">
                                <span class="page-link">
                                    2
                                    <span class="sr-only">(current)</span>
                                </span>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li> -->

                        <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                            <a class="page-link" tabindex="-1" (click)="nextPage()">Suivant <i
                                    class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                        </li>
                    </ul>
                </nav>

                <!-- <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                            class="pe-7s-trash btn-icon-wrapper"> </i></button>
                    <button class="btn-wide btn btn-success">Save</button> -->
            </div>
        </div>
    </div>
</div>