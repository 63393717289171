import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';
import { LoginComponent } from './login/login.component';
import { DetailsCourseComponent } from './pages/courses/details-course/details-course.component';
import { ListCoursesComponent } from './pages/courses/list-courses/list-courses.component';
import { PendingCoursesComponent } from './pages/courses/pending-courses/pending-courses.component';
import { DashboardComponent } from './pages/dashboard/dashboard/dashboard.component';
import { StatusCommandesComponent } from './pages/dashboard/status-commandes/status-commandes.component';
import { DetailsTaxiDriverComponent } from './pages/taxi-drivers/details-taxi-driver/details-taxi-driver.component';
import { PositionsDriversComponent } from './pages/taxi-drivers/positions-drivers/positions-drivers.component';
import { TaxiDriversComponent } from './pages/taxi-drivers/taxi-drivers/taxi-drivers.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent, canActivate: [AuthGuard],
    children: [

      // Dashboads
      { path: '', component: DashboardComponent, data: { extraParameter: 'dashboardsMenu' } },
      { path: 'status-commandes', component: StatusCommandesComponent, data: { extraParameter: 'componentsMenu' } },
      
      { path: 'taxi-drivers', component: TaxiDriversComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'detail-taxi-driver', component: DetailsTaxiDriverComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'positions-drivers', component: PositionsDriversComponent, data: { extraParameter: 'componentsMenu' } },
    

      { path: 'details-course', component: DetailsCourseComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'pending-courses', component: PendingCoursesComponent, data: { extraParameter: 'componentsMenu' } },
      { path: 'courses', component: ListCoursesComponent, data: { extraParameter: 'componentsMenu' } },
      
    ]
  },
  
  { path: '', redirectTo: '', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
