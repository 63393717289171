<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-plane icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>Demandes en cours
                <div class="page-title-subheading">La liste des demandes en cours.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-header">Courses en cours
                <!-- <div class="btn-actions-pane-right">
                    <div role="group" class="btn-group-sm btn-group">
                        <button class="active btn btn-focus">Last Week</button>
                        <button class="btn btn-focus">All Month</button>
                    </div>
                </div> -->

                <!-- <div id="toast_target"></div>
                <button ejs-button [isPrimary]="true" (click)="btnClick($event)">Show Toast</button>
                <ejs-toast #element (beforeOpen)="onBeforeOpen($event)" [position]='position'>
                    <ng-template #title>
                        <div>Matt sent you a friend request</div>
                    </ng-template>
                    <ng-template #content>
                        <div>Hey, wanna dress up as wizards and ride our hoverboards?</div>
                    </ng-template>
                </ejs-toast> -->

            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="allDemandes.length === 0">
                    <h5 class="text-center">Aucune courses en cours</h5>
                </div>
                <table class="align-middle mb-0 table table-borderless table-striped table-hover"
                    *ngIf="allDemandes.length > 0">
                    <thead>
                        <tr>
                            <th class="text-center">#</th>
                            <th>Utilisateurs</th>
                            <th class="text-center">Date<br>de<br>la course</th>
                            <th class="text-center">Localisation</th>
                            <th class="text-center">Destination</th>
                            <th class="text-center">Détails<br>de<br>la course</th>
                            <th class="text-center">Etat</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let demande of allDemandes, let index = index"  (click)="goToNewTab(demande._id, '/details-course')">
                            <td class="text-center text-muted">#{{index+1}}</td>
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <img width="40" height="40" class="rounded-circle" src="{{'https://assets.marketbey.tn/Wassali/TRANSPORTOR/users-trans/' + 
                                                ((demande?.user_transportor?.profile_user!=null && demande?.user_transportor?.profile_user!='') 
                                                ? demande?.user_transportor?.profile_user : 'profile.jpg')}}" alt="">
                                                <!-- <ngx-avatar class="rounded-circle" name="{{demande.user_transportor.prenom_user}}
                                            {{demande.user_transportor.nom_user}}">
                                            </ngx-avatar> -->
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading"
                                                (click)="(demande?.isDriver) && goToNewTab(demande?.idDriver, '/detail-taxi-driver')">
                                                {{demande?.user_transportor?.prenom_user}}
                                                {{demande?.user_transportor?.nom_user}}</div>
                                            <div class="widget-subheading opacity-7">
                                                {{demande?.user_transportor?.email_user}}</div>
                                            <div class="widget-subheading">
                                                {{demande?.user_transportor?.tel_user}}</div>
                                            <div
                                                [class]="(demande?.isDriver) ? 'badge badge-danger' : 'badge badge-success'">
                                                {{(demande?.isDriver) ? 'Chauffeur' : 'Client'}}</div>
                                            <div class="widget-subheading"
                                                *ngIf="demande?.flux != null && demande?.flux != ''">
                                                <b>→ {{demande?.flux}}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">{{demande?.action_date_course | date:'d MMM y HH:mm'}}</td>
                            <td class="text-center">{{demande?.user_location?.main_target}}<br>
                                <strong>{{demande?.user_location?.sencondary_target}}</strong>
                            </td>
                            <td class="text-center">{{demande?.user_destination?.main_target}}<br>
                                <strong>{{demande?.user_destination?.sencondary_target}}</strong>
                            </td>
                            <td>
                                Type de service: <strong>{{demande?.type_vehicule}}</strong><br>
                                Prix de la course: <strong>{{demande?.price_course | number:'0.3'}} TND</strong><br>
                                Prix bagage: <strong>{{demande?.unity | number:'0.3'}} TND</strong><br>
                                <b>Prix total: </b><strong style="color: tomato;">{{demande?.total_price | number:'0.3'}} TND
                                    DT</strong>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusDemandes[demande?.etat_course]?.color}}">
                                    {{statusDemandes[demande?.etat_course]?.label}}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>